<template>
    <div class="warp"
         v-loading="Loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="main">
        <div>
          <div class="jlkj">
            <img class="ztdqs" src="../../../public/static/plug/seaman/static/images/16.png" :style="{ top: ztValue.top + 'px',left:ztValue.left+ 'px' }">
            <div v-for="o in 29" :key="o">
              <div :class="`jllib${o-1}`">
                  <div v-if="info.plug_config_info.plug_seaman_items.length > o-1">
                    <el-tooltip class="item" effect="dark" content="" :placement="WzType(o)">
                        <div slot="content">
                            <div style="color: #babd9a;width: 113px;">
                                <p style="text-align:center;margin-bottom:6px;">{{ info.plug_config_info.plug_seaman_items[o-1].name }}</p>
                                <p style="margin-bottom:6px;" v-for="va, indx in info.plug_config_info.plug_seaman_items[o-1].code"  :key="indx">{{ va.name }}x{{ va.num }}</p>
                            </div>
                        </div>
                        <div  class="" :class="{'statesb':info.plug_config_info.plug_seaman_items[o-1].type == 1}">
                            <div>
                                <img  :src="info.plug_config_info.plug_seaman_items[o-1].ioc" style="width: 28px;position: relative;left: 16px;top: 16px;;height: 28px;">
                                <!-- 状态 -->
                                <!-- <img v-if="ztlx_index == 1" src="../../../public/static/plug/seaman/static/images/16.png" style="width: 48px;position: relative;left: 12px;top: -27px;height: 28px;height: 48px;">  
                                <img v-if="ztlx_index == 2" src="../../../public/static/plug/seaman/static/images/16.png" style="width: 48px;position: relative;left: 12px;top: -28px;height: 28px;height: 48px;">  
                                <img v-if="ztlx_index == 3" src="../../../public/static/plug/seaman/static/images/16.png" style="width: 48px;position: relative;left: 12px;top: -29px;height: 28px;height: 48px;">  
                                <img v-if="ztlx_index == 4" src="../../../public/static/plug/seaman/static/images/16.png" style="width: 48px;position: relative;left: 12px;top: -30px;height: 28px;height: 48px;">   -->
                            </div>
                        </div>    
                    </el-tooltip> 
                  </div>
                  
              </div>
            </div>
            
             
          </div>
          <div>
             <div>
                <div class="username"
                    style="color: #ff6161;position: absolute;width: 299px;left: 100px;top: 113px;text-align: center;">
                <span>当前角色：</span><span>{{ info.charac_name }}</span><a class="logout"
                    href="javascript:;"
                    @click="LoginDisplay = true">《登录|切换》</a>
                </div>
                <div  :class="xzi">
                   <div v-if="xzi == 'xzi'">
                        <img v-if="xz1 == 1" src="../../../public/static/plug/seaman/static/images/xz/11.png" style="width: 25px;position: relative;left: 32px;top: 26px;height: 23px;">
                        <img v-if="xz1 == 2" src="../../../public/static/plug/seaman/static/images/xz/12.png" style="width: 55px;position: relative;left: 16px;top: 16px;height: 45px;">
                        <img v-if="xz1 == 3" src="../../../public/static/plug/seaman/static/images/xz/13.png" style="width: 57px;position: relative;left: 16px;top: 15px;height: 47px;">  
                        <img v-if="xz1 == 4" src="../../../public/static/plug/seaman/static/images/xz/14.png" style="width: 59px;position: relative;left: 16px;top: 15px;height: 47px;"> 
                        <img v-if="xz1 == 5" src="../../../public/static/plug/seaman/static/images/xz/15.png" style="width: 59px;position: relative;left: 16px;top: 15px;height: 47px;">  
                        <img v-if="xz1 == 6"  src="../../../public/static/plug/seaman/static/images/xz/16.png" style="width: 60px;position: relative;left: 16px;top: 15px;height: 48px;">  
                   </div>
                </div>
                <div  :class="xzi" style="margin-left:160px;">
                    <div v-if="xzi == 'xzi'">
                        <img v-if="xz2 == 1" src="../../../public/static/plug/seaman/static/images/xz/11.png" style="width: 25px;position: relative;left: 32px;top: 26px;height: 23px;">
                        <img v-if="xz2 == 2" src="../../../public/static/plug/seaman/static/images/xz/12.png" style="width: 55px;position: relative;left: 16px;top: 16px;height: 45px;">
                        <img v-if="xz2 == 3" src="../../../public/static/plug/seaman/static/images/xz/13.png" style="width: 57px;position: relative;left: 16px;top: 15px;height: 47px;">  
                        <img v-if="xz2 == 4" src="../../../public/static/plug/seaman/static/images/xz/14.png" style="width: 59px;position: relative;left: 16px;top: 15px;height: 47px;"> 
                        <img v-if="xz2 == 5" src="../../../public/static/plug/seaman/static/images/xz/15.png" style="width: 59px;position: relative;left: 16px;top: 15px;height: 47px;">  
                        <img v-if="xz2 == 6"  src="../../../public/static/plug/seaman/static/images/xz/16.png" style="width: 60px;position: relative;left: 16px;top: 15px;height: 48px;">  
                   </div>
                </div>
                <!-- <div class="zlyic"></div>  -->
             </div>
             <div>
                <div style="width: 222px;text-align: center;position: absolute;left: 139px;top: 330px;color:#ff6161;">
                    <span>{{ info.plug_config_info.consumption }}</span>
                </div> 
                <div v-if="Click == false" class="xzann" @click="StartLucky" ></div>
                <div v-else class="xzann1" @click="StartLucky" ></div>
             </div>
          </div>
        </div>
      </div>
      <div class="titlehover">……</div>
      <div class="alert">
        <!-- 弹出框 -->
        <div class="alert_bg" v-if="MessageDisplay">
          <div class="alert_main">
            <div class="alert_title"><span>提示</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <span>{{ MessageMsg }}</span>
                <div class="btn"
                     @click="CloseAlert">关闭</div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <!-- 弹出框end -->
        <!-- 登陆框 -->
        <div class="alert_bg" v-if="LoginDisplay">
          <div class="alert_main"
               style="margin-top:86px;width:350px;">
            <div class="alert_title"><span>绑定|切换</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏账号：</span><input type="text"
                         v-model="login_info.username"
                         class="service__input"></p>
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏密码：</span><input type="password"
                         v-model="login_info.password"
                         class="service__input"></p>
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">绑定角色：</span>
                  <select v-model="login_info.role"
                          style="width:154px;">
                    <option v-for="(itmec, i) in role_data"
                            :key="i"
                            :value="itmec.charac_no">
                      {{ itmec.charac_name }}</option>
                  </select>
                  <span style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;"
                        @click="getRole">获取</span>
                </p>
                <div>
                  <span style="padding:0px 0px;color:#f90000;"><span style="display:inline-block;padding:0px 0px;">{{ LoginError }}</span></span>
                </div>
                <div style="text-align:center;padding:10px 0px">
                  <div class="btn"
                       @click="login"
                       style="margin:0px 0px;display:inline-block;">绑定</div>
                  <div class="btn"
                       @click="LoginDisplay = false"
                       style="margin:0px 0px;display:inline-block;">取消</div>
                </div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <!-- 购买资格 -->
        <div class="alert_bg pop-buy" v-if="BuyDisplay">
            <div class="alert_main">
            <i style="position: relative;left: 143px;top: 113px;z-index: 20;font-size: 18px;color: #bc9b9b;"  @click="BuyDisplay = false">x</i>
            <div class="alert_title" style="margin-top:92px;"><span>启动资格</span></div>
            <div class="alert_con">
                <div class="alert_com">
                  <div style="width: 93%;display: inline-block;float: left;text-align: left;padding: 10px 10px;color: #ff7878;">
                      <div style="border: 1px dashed #444; position: relative">
                        <div>
                          <p>每购买一次启动机会，需消耗{{ info.plug_config_info.user.type_name }}，请选择需要购买次数</p>
                          <input type="text" v-model="buy_num" max="100" placeholder="输入需要购买次数" />
                        </div>
                      </div>   
                  </div>
                  <div style="display: inline-block;float: right;position: absolute;top: 40px;right: 14px;">
                      <a class="btn cera"
                      href="javascript:;" style="margin-top: 22px; margin-right: 5px;" @click="Buy">&nbsp;购买</a>
                  </div>
                </div>
            </div>
            <div class="alert_bottom"><span></span></div>
            </div>
        </div>
      </div>
    </div>
  
  </template>
  <script>
  import Vue from 'vue'
  export default {
    name: "Monthsign",
    data () {
      return {
        MessageDisplay: false, //弹出框显示
        LoginDisplay: false, //登陆弹出框
        LoginError: '',
        MessageMsg: '', //弹出框消息
        Loading: false,
        BuyDisplay:false,
        Click:false,
        info: {
          m_id: 0, //账号uid
          charac_no: 0, //角色ID
          charac_name: '', //角色名称
          plug_config_info:{
            plug_seaman_items:[],
            consumption:'',
            dq_index:0,//当前位置
          },
        },
        login_info: {
          username: '',
          password: '',
          role: '',
        },
        role_data: [],
        ztlis:'', //编号
        ztlis_num:0, //次数
        buy_num:1,
        results:'',
        xzi:'xzi', //当前xz类型
        cj_index:0, //抽奖编号
        ztlx_index:0,
        ztlx_num:0,
        ztValue:{top:0,left:14},
        leftValue:14,
        xz1:6,
        xz2:6,
        ls_index:0,
        dwdata:[
            {top:438,left:14},
            {top:376,left:14},
            {top:316,left:14},
            {top:254,left:14},
            {top:192,left:14},
            {top:130,left:14},
            {top:68,left:14},
            {top:6,left:14},
            {top:6,left:76},
            {top:6,left:138},
            {top:6,left:200},
            {top:6,left:262},
            {top:6,left:324},
            {top:6,left:386},
            {top:6,left:448},
            {top:68,left:448},
            {top:130,left:448},
            {top:192,left:448},
            {top:254,left:448},
            {top:316,left:448},
            {top:378,left:448},
            {top:440,left:448},
            {top:440,left:386},
            {top:440,left:324},
            {top:440,left:262},
            {top:440,left:200},
            {top:440,left:138},
            {top:440,left:76},
        ],
      };
    },
    created () {
     this.getInfo()
    },
    methods: {
      //获取基本配置信息
      getInfo () {
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/seaman/getSeamanInfo',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.info = response.data.info;
            this.ztValue = this.dwdata[this.info.plug_config_info.dq_index]
          } else {
            this.PopMessage(true, response.data.msg)
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      //登陆
      login () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/PlugLogin',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
            role: this.login_info.role,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.LoginDisplay = false
            this.getInfo()
          } else {
            this.LoginError = response.data.msg
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      //获取角色
      getRole () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/getRole',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.role_data = response.data.info
          } else {
            this.LoginError = response.data.msg
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      
      //弹出框状态
      PopMessage (type, msg) {
        this.MessageDisplay = false
        this.MessageMsg = msg
        if (type == true) {
          this.MessageDisplay = true
        } else {
          this.getInfo()
        }
      },
      //关闭窗口
      CloseAlert () {
        this.PopMessage(false, '')
      }, 
      //启动提交
      StartLucky(){
        var that = this;
        if (that.Loading == true) {
          return false
        }
        that.Click = true
        that.Loading = true
        that.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/seaman/SeamanStartLucky',
          // 传递参数
          data: {
            key: that.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          that.Loading = false
          that.BuyDisplay = false
          if (response.data.code == 200) {
            this.xz1 = response.data.info.xz1;
            this.xz2 = response.data.info.xz2;
            this.ls_index =  this.xz1+this.xz2;
            this.LuckyDrawZp()
          } else {
            that.Click = false;
            that.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          that.Click = false;
          that.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      LuckyDrawZp(){
        var that = this;
        setTimeout(function ()  {
            if(that.ztlis_num < 10){
              that.xzi = "xzi"+that.cj_index
              if( that.cj_index >= 2){
                that.cj_index = 0;
              }else{
                that.cj_index += 1;
              }
              that.ztlis_num += 1;
              that.LuckyDrawZp();
            }else{
              that.xzi = "xzi";
              that.cj_index = 0;
              that.ztlis_num = 0;
              that.ZtlxIndex(that.ls_index);
            }
        },70);
      },
      ZtlxIndex(vales){
        var that = this;
        for (let i = 0; i < vales; i++) {
            //判断当前位置
            (function(index) {
            setTimeout(function() {
                if(that.info.plug_config_info.dq_index == 27){
                    that.info.plug_config_info.dq_index = 0; 
                }else{
                    that.info.plug_config_info.dq_index += 1;
                }
                console.log(that.info.plug_config_info.dq_index)
                that.ztValue = that.dwdata[that.info.plug_config_info.dq_index]
            }, index * 300);
            })(i);
        }
        setTimeout(function(){
          that.Click = false;
        }, vales*300);
       
      },
      WzType(o){
        if(o == 1){
          return "right-start";
        }else if(o > 1 && o <= 7){
          return "right";
        }else if(o == 8){
          return "right-end";
        }else if(o > 8 && o <= 14){
          return "bottom";
        }else if(o == 15){
          return "bottom-end";
        }else if(o > 15 && o <= 21){
          return "left";
        }else if(o == 22){
          return "left-start";
        }else if(o > 22 && o <= 28){
          return "top";
        }
        return "top";
      },
      Buy(){
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/stage/BuyStage',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            num:this.buy_num,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          this.BuyDisplay = false
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      Receive(){
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/stage/StageReceive',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          this.BuyDisplay = false
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
    },
  };
  </script>
  
  
  <style scoped>
  @import "../../../public/static/plug/seaman/static/css/game.css";
  </style>
  

  

<style>
#root,
body,
html {
    min-width: 0px;
}
</style>
<style lang="scss" scoped>
 /deep/ .el-row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
</style>
  
  